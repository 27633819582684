async function initClientApp () {
  try {
    const { default: React } = await import('react');
    const { createRoot } = await import('react-dom/client');
    const {
      ApolloClient,
      ApolloProvider,
      InMemoryCache,
      // createHttpLink,
    } = await import('@apollo/client');
    const { setContext } = await import('@apollo/client/link/context');
    const { onError } = await import('@apollo/client/link/error');
    const { default: createUploadLink } = await import('apollo-upload-client/createUploadLink.mjs');
    const { MantineProvider, createTheme, rem } = await import('@mantine/core');
    const { default: MainApp } = await import('./layouts/MainApp');
    const { default: AppSettingsProvider } = await import('./components/Common/AppSettingsProvider');
    const { default: auth, sessionKeys } = await import('./helpers/auth');

    auth.init();

    window.addEventListener('load', () => {
      window.env = window.env || {};

      /**
       * API:
       *  https://www.apollographql.com/docs/react/api/core/ApolloClient
       *  https://www.apollographql.com/docs/react/networking/authentication#header
       *  https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
       */
      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          console.error('[ApolloClient error]', graphQLErrors);
        }

        if (networkError && networkError.statusCode === 401) {
          window.location = `${window.env.basename}/login`;
        } else if (networkError) {
          console.error('[ApolloClient network error]', networkError);
        }
      });

      const authLink = setContext((_, { headers }) => {
        const token = auth.getValue(sessionKeys.userToken);
        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : null,
          },
        };
      });

      const theme = createTheme({
        fontSmoothing: false,
        fontSizes: {
          xs: rem(10),
          sm: rem(12),
          md: rem(14),
          lg: rem(16),
          xl: rem(20),
        },
        other: {
          fontWeights: {
            normal: 300,
            semiBold: 500,
            bold: 600,
          },
        },
      });

      const graphClient = new ApolloClient({
        cache: new InMemoryCache({
          typePolicies: {
            LandlordTransactionDuplicate: {
              keyFields: ['id', 'matchedId'],
            },
          },
        }),
        link: errorLink
          .concat(authLink)
          // .concat(createHttpLink({
          //   uri: window.env.API_URL,
          // }))
          .concat(createUploadLink({
            uri: window.env.API_URL,
            headers: {
              'Apollo-Require-Preflight': 'true',
            },
          })),
      });

      const container = window.document.getElementById('react-app');
      const root = createRoot(container);
      root.render((
        <AppSettingsProvider>
          <MantineProvider defaultColorScheme="light" theme={ theme }>
            <ApolloProvider client={ graphClient }>
              <MainApp />
            </ApolloProvider>
          </MantineProvider>
        </AppSettingsProvider>
      ));
    });
  } catch (error) {
    console.error('Error during initialization:', error);
  }
}

initClientApp();
